import anime, { stagger } from 'animejs';
// import FPS from './utils/FPS';
// import Utils from './utils/Utils';

const updateVh = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const isMobile = () => {
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
};

updateVh();

if (!isMobile()) {
    window.addEventListener('resize', updateVh);
}

(function() {
    
    const DOM = {
        sEnter: document.querySelector('.h_enter'),
        sEnterCover: document.querySelector('.h_enter_cover'),
        sIntro: document.querySelector('.h_intro'),
        sAbout: document.querySelector('.h_about'),
        bg: document.querySelectorAll('.h_bg'),
        videos: document.querySelectorAll('.h_video'),
        cursor: document.querySelector('.cursor'),
        navToIntro: document.querySelector('.h_nav--toIntro'),
        navToAbout: document.querySelector('.h_nav--toAbout'),
        introOverlay: document.querySelector('.h_intro_overlay'),
        socials: document.querySelector('.h_socials'),
        equalizer: document.querySelector('.equalizer'),
        equalizerBars: document.querySelectorAll('.equalizer_bar'),
        audio: document.querySelector('audio'),
        words: document.querySelector('.randomText'),
        reel: document.querySelector('.reel'),
        reelButton: document.querySelector('.reelButton'),
        reelVideo: document.querySelector('.reel_video'),
        reelBg: document.querySelector('.reel_bg')
    };

    setTimeout(() => {
        DOM.sEnterCover.classList.add('visible');
    }, 500);

    const nbVideos = DOM.videos.length;

    let isAnimating = true;
    let direction = 0;
    let minimumScroll = 5;

    let isIntroActive = true;
    let isAboutActive = false;

    let isIntroAnimated = false;
    let isAboutAnimated = false;

    let audioPlaying = false;
    let forcePause = false;

    const onScroll = (event) => {
        if (isAnimating === false && (Math.abs(event.deltaY) > minimumScroll || Math.abs(event.deltaX) > minimumScroll)) {
            direction = (event.deltaY > 0 || event.deltaX > 0) ? 1 : -1;
            animateSlides();
        }
    };


    document.querySelectorAll('[data-cursor="default"]').forEach((elt) => {
        elt.addEventListener('mouseenter', () => { DOM.cursor.setAttribute('data-state', 'default'); } );
    });
    
    document.querySelectorAll('[data-cursor="close"]').forEach((elt) => {
        elt.addEventListener('mouseenter', (e) => DOM.cursor.setAttribute('data-state', 'close'));
        elt.addEventListener('mouseleave', () => DOM.cursor.setAttribute('data-state', 'default'));
    });

    document.querySelectorAll('[data-cursor="reel"]').forEach((elt) => {
        elt.addEventListener('mouseenter', (e) => DOM.cursor.setAttribute('data-state', 'reel'));
        elt.addEventListener('mouseleave', () => DOM.cursor.setAttribute('data-state', 'default'));
    });


    const onKeyPress = (event) => {
        if (isAnimating) {
            return;
        }

        if (event.keyCode == 37 || event.keyCode == 38) {
            direction = -1;
            animateSlides();
        }

        if (event.keyCode == 39 || event.keyCode == 40) {
            direction = 1;
            animateSlides();
        }
    };

    DOM.navToIntro.addEventListener('click', () => {
        direction = -1;
        animateSlides();
    });
    DOM.sAbout.querySelector('.h_about_bg').addEventListener('click', () => {
        direction = -1;
        animateSlides();
    });

    DOM.navToAbout.addEventListener('click', () => {
        direction = 1;
        animateSlides();
    });

    DOM.introOverlay.addEventListener('click', () => {
        direction = 1;
        animateSlides();
    });

    // const onTouch = () => {
    //     let startY,
    //         dist,
    //         limit = 75;

    //     window.addEventListener('touchstart', function (e) {
    //         if (isAnimating) return;

    //         let touchobj = e.changedTouches[0];
    //         dist = 0;
    //         startY = touchobj.pageY;
    //     }, false);

    //     window.addEventListener('touchmove', function (e) {}, false);

    //     window.addEventListener('touchend', function (e) {
    //         if (isAnimating) return;

    //         let touchobj = e.changedTouches[0];
    //         dist = touchobj.pageY - startY;

    //         if (dist > limit) {
    //             direction = -1;
    //             animateSlides();
    //         } else if (dist * -1 > limit) {
    //             direction = 1;
    //             animateSlides();
    //         }
    //     }.bind(this), false);
    // };

    // const isTouch = () => {
    //     return 'ontouchstart' in document.documentElement;
    // }

    const showAbout = () => {
        DOM.audio.volume = 0.25;

        let TL = anime.timeline({
            duration: 1200,
            easing: 'easeInOutExpo',
            begin: () => {
                isIntroActive = false;
                isAboutActive = true;
            },
            complete: () => {
                isAnimating = false;
            }
        });

        DOM.reelButton.classList.add('onLeft');

        // TL.add({ targets: DOM.reelButton, translateX: ['0vw','-43vw'], translateZ: [0, 0] }, 0);
        TL.add({ targets: DOM.sAbout, translateX: ['101%','0%'], translateZ: [0, 0] }, 0);
        TL.add({ targets: DOM.bg, translateX: [0,'-25%'], opacity: [1,0.3], translateZ: [0, 0] }, 0);
        TL.add({ targets: DOM.sIntro, translateX: ['0%','-15%'], opacity: [1,0], translateZ: [0, 0] }, 0);

        TL.add({
            targets: DOM.navToAbout,
            opacity: [1,0],
            duration: 600,
            easing: "linear",
            complete: () => { DOM.navToAbout.style.display = "none"; }
        }, 0);

        TL.add({
            targets: DOM.navToIntro,
            opacity: [0,1],
            duration: 600,
            easing: "linear",
            begin: () => { DOM.navToIntro.style.display = "block"; }
        }, 1000);

        TL.add({
            targets: DOM.socials,
            opacity: [0,1],
            duration: 600,
            easing: "linear",
            begin: () => { DOM.socials.style.display = "block"; },
        }, 1200);

        setTimeout(() => {
            animateAbout(); 
        }, 800);
    };

    const animateAbout = () => {
        let TL = anime.timeline({
            duration: 1200,
            easing: 'easeOutExpo'
        });

        TL.add({
            targets: [DOM.sAbout.querySelectorAll('.st4'), DOM.sAbout.querySelector('.st3'), DOM.sAbout.querySelector('.h_about_txt'), DOM.sAbout.querySelectorAll('.h_about_works .row') ],
            translateX: ['120px', '0'],
            opacity: [0,1],
            delay: anime.stagger(50),
        });
    };

    const showIntro = () => {
        DOM.audio.volume = 1;

        let TL = anime.timeline({
            duration: 1200,
            easing: 'easeInOutExpo',
            begin: () => {
                isIntroActive = true;
                isAboutActive = false;
            },
            complete: () => {
                isAnimating = false;

                anime.set([DOM.sAbout.querySelectorAll('.st4'), DOM.sAbout.querySelector('.st3'), DOM.sAbout.querySelector('.h_about_txt'), DOM.sAbout.querySelectorAll('.h_about_works .row') ],
                {
                    translateX: '120px',
                    opacity: 0,
                });
            }
        });

        TL.add({ targets: DOM.sIntro, translateX: ['-15%', 0], opacity: [0,1], translateZ: [0, 0] }, 0);
        TL.add({ targets: DOM.bg, translateX: ['-25%', 0], opacity: [0.3,1], translateZ: [0, 0] }, 0);
        TL.add({ targets: DOM.sAbout, translateX: ['0%','101%'], translateZ: [0, 0] }, 0);
        // TL.add({ targets: DOM.reelButton, translateX: ['-43vw','0vw'], translateZ: [0, 0] }, 0);
        DOM.reelButton.classList.remove('onLeft');

        TL.add({
            targets: DOM.navToIntro,
            opacity: [1,0],
            duration: 600,
            easing: "linear",
            complete: () => { DOM.navToIntro.style.display = "none"; }
        }, 0);

        TL.add({
            targets: DOM.socials,
            opacity: [1,0],
            duration: 600,
            easing: "linear",
            complete: () => { DOM.socials.style.display = "none"; }
        }, 0);

        TL.add({
            targets: DOM.navToAbout,
            opacity: [0,1],
            duration: 600,
            easing: "linear",
            begin: () => { DOM.navToAbout.style.display = "block"; }
        }, 1000);
    };

    const showIntroContents = (parent) => {
        let old;
        if (DOM.sIntro.querySelector('.st1.active')) {
            old = DOM.sIntro.querySelector('.st1.active');
            old.classList.remove('active');

            anime({
                targets: old.querySelectorAll('.line > div'),
                translateY: ['0%', '-105%'],
                duration: 1200,
                easing: 'easeInOutExpo',
                delay: anime.stagger(100),
            });
        }

        if (parent) parent.classList.add('active');

        setTimeout(() => {
            anime({
                targets: parent.querySelectorAll('.line > div'),
                translateY: ['105%', '0%'],
                duration: 1200,
                easing: 'easeInOutExpo',
                delay: anime.stagger(100),
            });
        }, old ? 400 : 0);
    };

    const animateIntroContents = () => {
        let contents = DOM.sIntro.querySelectorAll('.st1');
        let currentContent = 0;
        let maxContent = contents.length - 1;

        showIntroContents(contents[currentContent]);

        setInterval(() => {
            currentContent = currentContent == maxContent ? 0 : currentContent + 1;
            console.log();
            showIntroContents(contents[currentContent]);
        }, 4000);
    };

    const animateIntro = () => {
        if (isIntroAnimated == true) return;

        isIntroAnimated = true;

        setTimeout(() => {
            isAnimating = false;
        }, 2000);

        let TL = anime.timeline({
            duration: 1200,
            easing: 'easeInOutExpo',
        });

        TL.add({
            targets: DOM.bg,
            opacity: [0, 1],
            duration: 600,
        }, 600);

        TL.add({
            targets: DOM.navToAbout,
            opacity: [0,1],
            duration: 600,
            easing: 'linear',
            begin: () => { DOM.navToAbout.style.display = "block"; }
        }, 2000);

        setTimeout(() => {
            animateIntroContents();
        }, 1000);

    };

    const animateSlides = () => {
        if (direction > 0 && !isAboutActive) {
            isAnimating = true;
            showAbout();
        }
        
        if (direction < 0 && !isIntroActive) {
            isAnimating = true;
            showIntro();
        }
    };

    const hideEnter = () => {
        DOM.cursor.setAttribute('data-state', 'default');

        if (isMobile()) {
            anime({
                targets: [DOM.sEnter.querySelector('.h_enter_mobileTitle'), DOM.sEnter.querySelector('.h_enter_indicator')],
                opacity: 0,
                duration: 200,
                easing: 'linear'
            });
            document.documentElement.classList.add('scrollable');
        }

        anime({
            targets: DOM.sEnter,
            opacity: 0,
            scale: [1,1.5],
            duration: 1200,
            delay: isMobile() ? 300 : 0,
            easing: 'easeOutExpo',
            complete: () => {
                DOM.sEnter.style.display = 'none';
            }
        });

        animateIntro();
    };

    if (!isMobile()) {
        // document.addEventListener("wheel", onScroll);
        document.addEventListener("keyup", onKeyPress);
    }

    let activateMouseMove = false;

    // PRELOAD VIDEOS
    DOM.videos.forEach((slide) => {
        let video = slide.querySelector('video');
        let src = video.getAttribute('data-src');
        video.src = src;
        
        // PLAY ACTIVE VIDEO ON LOAD
        if (slide.classList.contains("active")) {
            video.addEventListener('loadedmetadata', () => { video.play(); activateMouseMove = true; });
            video.addEventListener('loadeddata', () => { video.play(); activateMouseMove = true; });
        }
    });

    window.addEventListener('mousemove', e => {
        if (!activateMouseMove) return;
        
        let index = Math.floor(e.clientX / (window.innerWidth/nbVideos));

        // PAUSE & HIDE OLD VIDEO
        let oldVideo = document.querySelector('.h_video.active');
        oldVideo.querySelector('video').pause();
        oldVideo.classList.remove('active');

        // PLAY AND SHOW NEW VIDEO
        let newVideo = document.querySelectorAll('.h_video')[index];
        newVideo.querySelector('video').play();
        newVideo.classList.add('active');

        DOM.cursor.style.opacity = 1;
        DOM.cursor.style.transform = 'translate(' + e.clientX + 'px,' + e.clientY + 'px)';
    });

    document.addEventListener('mouseleave', () => {
        DOM.cursor.style.opacity = 0;
    });

    DOM.sAbout.querySelectorAll('.h_about .st3 .link').forEach((link) => {
        let num = link.getAttribute('data-person');
        let desc = document.querySelector('.h_about_desc[data-person="' + num + '"]');
        
        link.addEventListener('mouseenter',() => {
            desc.classList.add('active');
            DOM.sAbout.querySelector('.h_content').classList.add('hover');
        });
        link.addEventListener('mouseleave',() => {
            desc.classList.remove('active');
            DOM.sAbout.querySelector('.h_content').classList.remove('hover');
        });
    });

    DOM.sEnter.addEventListener('click', () => {
        hideEnter();
        audioPlaying = true;
        DOM.audio.play();
    });

    let modifier = 0.03;
    DOM.sEnter.addEventListener('mousemove', (e) => {
        if (isMobile()) return;

        let movementMax = modifier * window.innerWidth * -1; // inverse with -1
        let center = [window.innerWidth/2, window.innerHeight/2];
        DOM.sEnterCover.style.transform = 'translate(' + ((e.pageX - center[0]) / center[0]) * movementMax + 'px,' +  ((e.pageY - center[1]) / center[1]) * movementMax + 'px)';
    });

    setTimeout(() => {
        hideEnter();
    }, 10000);

    const playEqualizer = () => {
        setTimeout(function() {
            rafEqualizer = window.requestAnimationFrame(playEqualizer);
        
            DOM.equalizerBars.forEach(function(bar, index) {
                bar.style.transform = audioPlaying ? 'scaleY(' + Math.random() + ')' : 'scaleY(0.1)';
            });
        }, 150);
    };
    playEqualizer();

    DOM.equalizer.addEventListener('click', () => {
        if (audioPlaying) {
            DOM.audio.pause();
        } else {
            DOM.audio.play();
        }
        
        audioPlaying = !audioPlaying;

        if (audioPlaying) {
            DOM.equalizer.classList.remove('disabled');
        } else {
            DOM.equalizer.classList.add('disabled');
        }
    });

    let wordsInterval = null;
    let wordsString = DOM.words.getAttribute('data-words');
    let words = wordsString.split(',');
    let currentWordIndex = 0;

    const shuffleWords = () => {
        wordsInterval = setInterval(() => {
            currentWordIndex = currentWordIndex == words.length - 1 ? 0 : currentWordIndex + 1;
            DOM.words.innerHTML = words[currentWordIndex];
        }, 75);
    };

    DOM.sIntro.querySelector('.two').addEventListener('mouseenter', shuffleWords);
    DOM.sIntro.querySelector('.two').addEventListener('mouseleave', () => {
        clearInterval(wordsInterval);
    });


    let saveAudioState = null;
    DOM.reelButton.addEventListener('click', () => {
        DOM.reel.classList.add('visible');

        DOM.audio.pause();
        DOM.equalizer.classList.add('disabled');
        saveAudioState = audioPlaying;
        audioPlaying = false;

        DOM.reelVideo.play();

        let modifier = 0.03;
        DOM.reel.addEventListener('mousemove', (e) => {
            if (isMobile()) return;
    
            let movementMax = modifier * window.innerWidth * -1; // inverse with -1
            let center = [window.innerWidth/2, window.innerHeight/2];
            DOM.reelBg.style.transform = 'translate(' + ((e.pageX - center[0]) / center[0]) * movementMax + 'px,' +  ((e.pageY - center[1]) / center[1]) * movementMax + 'px)';
        });
    });

    DOM.reelBg.addEventListener('click', () => {
        DOM.reel.classList.remove('visible');
        DOM.reelVideo.pause();
        
        if (saveAudioState == true) {
            audioPlaying = true;
            DOM.audio.play();
            DOM.equalizer.classList.remove('disabled');
        }
    });


})();